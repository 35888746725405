<template>
	<div class="d-flex">
		<counter-box :time="daysLeft" text="ДНЕЙ" />
		<span class="mx-4 pt-4">:</span>

		<counter-box :time="hoursLeft" text="ЧАСОВ" />
		<span class="mx-4 pt-4">:</span>

		<counter-box :time="minutesLeft" text="МИНУТ" />
		<span class="mx-4 pt-4">:</span>

		<counter-box :time="seconds" text="СЕКУНД" />
	</div>
</template>
<script>
import Counter from "./Counter";
import AppText from "@/components/shared-components/AppText";
import CounterBox from "@/components/pages/ProductList/reverse-counter/CounterBox";

export default {
	name: "reverse-counter",
	props: {
		days: {
			type: Number,
			default: 0,
		},
		hours: {
			type: Number,
			default: 0,
		},
		minutes: {
			type: Number,
			default: 0,
		},
		fullLength: {
			type: [Number, String],
			default: 0,
		},
		fullDate: {
			type: String,
			required: true,
		},
	},
	components: { Counter, AppText, CounterBox },
	data() {
		return {
			daysCounter: 0,
			hoursCounter: 0,
			minutesCounter: 0,
			seconds: 1,
			timer: null,
			definer: true,
			daysLeft: 0,
			hoursLeft: 0,
			minutesLeft: 0,
		};
	},
	created() {
		this.initTime();
		// this.initCounter();

		const endDate = new Date(this.fullDate).getTime() / 1000;
		const currentDate = Math.round(new Date().getTime() / 1000);
		let difference = endDate - currentDate;

		// Decrese function Dummy
		const intervalDecrease = setInterval(() => {
			if (difference > 0) {
				difference -= 1;
			} else {
				difference = 0;
			}
			checker();
			this.daysLeft = this.daysLeftF(difference);
			this.hoursLeft = this.hoursLeftF(difference);
			this.minutesLeft = this.minutesLeftF(difference);
			this.seconds = this.secondsLeftF(difference);
		}, 1000);

		function checker() {
			if (difference === 0 || difference < 0) {
				clearInterval(intervalDecrease);
			}
		}
	},
	methods: {
		daysLeftF(s) {
			return this.getTimerFormat(Math.floor(s / (3600 * 24)));
		},
		hoursLeftF(s) {
			return this.getTimerFormat(Math.floor((s % (3600 * 24)) / 3600));
		},
		minutesLeftF(s) {
			return this.getTimerFormat(Math.floor(s / 60) % 60);
		},
		secondsLeftF(s) {
			return this.getTimerFormat(Math.ceil(s) % 60);
		},
		initTime() {
			this.daysCounter = this.days;
			this.hoursCounter = this.hours;
			this.minutesCounter = this.minutes;
		},
		initCounter() {
			this.decrementMinutes();

			this.timer = setInterval(() => {
				this.decrementSeconds();
			}, 1000);
		},
		decrementSeconds() {
			if (this.minutesCounter <= 0 && this.hoursCounter <= 0 && this.daysCounter <= 0 && this.seconds <= 0)
				return;

			if (this.seconds >= 0) {
				this.seconds--;
			}

			if (this.seconds < 0 && this.decrementMinutes()) return;
		},
		decrementMinutes() {
			if (this.minutesCounter <= 0) {
				if (this.decrementHours()) return true;
			}
			this.minutesCounter -= 1;
			this.seconds = 59;

			if (this.definer) {
				this.definer = false;
				this.seconds = 60;
			}
		},
		decrementHours() {
			if (this.hoursCounter <= 0) {
				if (this.decrementDays()) return true;
			}
			this.hoursCounter -= 1;
			this.minutesCounter = 60;
		},
		decrementDays() {
			if (this.daysCounter <= 0) {
				clearInterval(this.timer);
				return true;
			}
			this.daysCounter -= 1;
			this.hoursCounter = 24;
		},
		resetCounter() {
			this.seconds = 0;
		},
		getTimerFormat(counter) {
			if (counter < 10) {
				return `0${counter}`;
			}
			return counter;
		},
	},
	beforeDestroy() {
		this.resetCounter();
	},
};
</script>
<style scoped>
.days,
.hours,
.minutes {
	width: 150px;
	height: 150px;
	border: 5px solid #ccc;
	padding: 10px 15px;
	border-radius: 50%;
	font-size: 12px;
}

.counter-wrap p {
	font-size: 10px;
	text-align: center;
	text-transform: uppercase;
}

.counter-wrap {
}
</style>

