<template>
	<div v-if="time" class="section__top-right d-flex">
		<slot></slot>
		<div class="d-flex">
			<div class="d-flex align-center flex-column">
				<div class="clock-box" style="min-height: 32px; width: 30px">
					<AppText weight="600" size="16">{{ days }}</AppText>
				</div>
				<app-text class="color-text" size="14" line-height="18">
					<AppText weight="600" size="10">Дней</AppText>
				</app-text>
			</div>
			<span class="mx-4 pt-4">:</span>
			<div class="d-flex align-center flex-column">
				<div class="clock-box" style="min-height: 32px; width: 30px">
					<AppText weight="600" size="16">{{ hours }}</AppText>
				</div>
				<app-text class="color-text" size="14" line-height="18">
					<AppText weight="600" size="10">Часов</AppText>
				</app-text>
			</div>
			<span class="mx-4 pt-4">:</span>
			<div class="d-flex align-center flex-column">
				<div class="clock-box" style="min-height: 32px; width: 30px">
					<AppText weight="600" size="16">{{ minutes }}</AppText>
				</div>
				<app-text class="color-text" size="14" line-height="18">
					<AppText weight="600" size="10"> Минут</AppText>
				</app-text>
			</div>
			<span class="mx-4 pt-4">:</span>
			<div class="d-flex align-center flex-column">
				<div class="clock-box" style="min-height: 32px; width: 30px">
					<AppText weight="600" size="16">{{ seconds }}</AppText>
				</div>
				<app-text class="color-text" size="14" line-height="18">
					<AppText weight="600" size="10"> Секунд</AppText>
				</app-text>
			</div>
		</div>
	</div>
</template>
<script>
import AppText from '@/components/shared-components/AppText';
import AppButtonA from '@/components/shared-components/AppButtonA';

export default {
	name: 'selection-time-counter',
	props: {
		time: {}
	},
	components: {AppText, AppButtonA},
	data() {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		}
	},
	mounted() {
		this.initCounter();
	},
	methods: {
		initCounter() {
			this.days = this.normalizedDate.days;
			this.hours = this.normalizedDate.hours;
			this.minutes = this.normalizedDate.minutes;
			this.seconds = this.normalizedDate.seconds;
			
			this.timer = setInterval(() => {
                this.decrementSeconds();
            }, 1000);
		},

		decrementSeconds() {
			if (this.minutes <= 0 && 
				this.hours <= 0 && 
				this.days <= 0 && 
				this.seconds <= 0) return;

			if (this.seconds >= 0) {
				this.seconds--;
			}

            if (this.seconds < 0 && this.decrementMinutes()) return;
        },
		decrementMinutes() {
            if (this.minutes <= 0) {
                if (this.decrementHours()) return true;
            }
            this.minutes -= 1;
            this.seconds = 59;
        },
        decrementHours() {
            if (this.hours <= 0) {
                if (this.decrementDays()) return true;
            }
            this.hours -= 1;
            this.minutes = 60;
        },
        decrementDays() {
            if (this.days <= 0) {
                clearInterval(this.timer);
                return true;
            }
            this.days -= 1;
            this.hours = 24;
        },
        resetCounter() {
            this.seconds = 0;
        }
	},
	computed: {
		normalizedDate() {
			let time = this.time
			const productDate = Date.parse(time);
			const todayDate = Date.parse(new Date());
			const timeDifference = Math.round((productDate - todayDate) / 1000);

			if (timeDifference < 0 || !time) {
				return {seconds: 0, minutes: 0, hours: 0, days: 0};
			}

			const seconds = timeDifference % 60,
				  minutes = Math.floor(timeDifference / 60) % 60,
				  hours = Math.floor(timeDifference / 3600) % 24,
				  days = Math.floor(timeDifference / 86400);

			return {seconds, minutes, hours, days};
		}
	}
}
</script>
<style lang="scss" scoped>
.clock-box {
	margin-bottom: 5px;
}
</style>