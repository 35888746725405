/**
 *
 * @param {String} string
 */
export function correctPrice(string, line = 3) {
	let result = "";
	let qoldiq = "";
	string.split(".").length > 1 ? (qoldiq = string.split(".")[1]) : null;
	string
		.split(".")[0]
		.split("")
		.reverse()
		.forEach((elem, index) => {
			if (index !== 0 && index % line === 0) {
				result += " ";
			}
			result += elem;
		});
	result = result.split("").reverse().join("");
	qoldiq.length > 0 ? (result += `.${qoldiq}`) : "";
	return result;
}

