<template>
 <div>
   <div class="base-timer">
     <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
       <g class="base-timer__circle">
         <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
         <path
                 :stroke-dasharray="circleDasharray"
                 class="base-timer__path-remaining"
                 :class="remainingPathColor"
                 d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
         ></path>
       </g>
     </svg>
     <span class="base-timer__label">{{ formattedTimeLeft }}</span>
   </div>

   <app-text class="text-center ttu mt-5" size="10" weight="500">{{ timeTitle }}</app-text>
 </div>
</template>

<script>
    import AppText from "./AppText";
    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 10;
    const ALERT_THRESHOLD = 5;

    const COLOR_CODES = {
        info: {
            color: "green"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
    };

    let TIME_LIMIT = 20;

    export default {
        name: "AppTimer",
        components: {AppText},
        data() {
            return {
                timePassed: 0,
                timerInterval: null,
				aDay: 24 * 60 * 60,
				anHour: 60 * 60
            };
        },
        props: {
            days: {
                type: Number,
                default: 0
            },

            hours: {
                type: Number,
                default: 0
            },

            minutes: {
                type: Number,
                default: 0
            },
            title: String
        },
        computed: {
            circleDasharray() {
                return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
            },

            formattedTimeLeft() {
                const timeLeft = this.timeLeft;
            	if (timeLeft >= this.aDay) {
            		return Math.floor(timeLeft / this.aDay)
				}
            	if (timeLeft >= this.anHour) {
            		return Math.floor(timeLeft / this.anHour)
				}
                let minutes = Math.floor(timeLeft / 60);
                let seconds = timeLeft % 60;

				if (minutes < 10) {
					minutes = `0${minutes}`;
				}

                if (seconds < 10) {
                    seconds = `0${seconds}`;
                }

                return `${minutes}:${seconds}`;
            },

            timeLeft() {
				return (this.days * this.aDay) + (this.hours * this.anHour) + (this.minutes * 60)  - this.timePassed;
            },

            timeFraction() {
                const rawTimeFraction = this.timeLeft / TIME_LIMIT;
                return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
            },

            remainingPathColor() {
                const {alert, warning, info} = COLOR_CODES;

                if (this.timeLeft <= alert.threshold) {
                    return alert.color;
                } else if (this.timeLeft <= warning.threshold) {
                    return warning.color;
                } else {
                    return info.color;
                }
            },
            timeTitle() {
                return this.title ? this.title : 'text';
            }
        },

        watch: {
            timeLeft(newValue) {
                if (newValue === 0) {
                    this.onTimesUp();
                }
            }
        },

        mounted() {
            this.startTimer();
        },

        methods: {
            onTimesUp() {
                clearInterval(this.timerInterval);
            },

            startTimer() {
                this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
            }
        }
    };
</script>
<style>
    
</style>
<style scoped lang="scss">
  .base-timer {
    position: relative;
    width: 40px;
    height: 40px;

    &__svg {
      transform: scaleX(-1);
    }

    &__circle {
      fill: none;
      stroke: none;
    }

    &__path-elapsed {
      stroke-width: 7px;
      stroke: grey;
    }

    &__path-remaining {
      stroke-width: 7px;
      stroke-linecap: round;
      transform: rotate(90deg);
      transform-origin: center;
      transition: 1s linear all;
      fill-rule: nonzero;
      stroke: currentColor;

      &.green {
        color: var(--color-secondary);
      }

      &.orange {
        color: var(--color-secondary);
      }

      &.red {
        color: red;
      }
    }

    &__label {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
    }
  }
</style>
