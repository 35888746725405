<template>
  <div class="d-flex align-center flex-column">
    <div class="clock-box" style="min-height: 32px; width: 30px">
      <AppText weight="600" size="16">{{ time }}</AppText>
    </div>
    <app-text class="color-text" size="14" line-height="18">
      <AppText weight="600" size="10">{{text}}</AppText>
    </app-text>
  </div>
</template>
<script>
import AppText from "@/components/shared-components/AppText";
export default {
  name: "counter",
  components: {
    AppText,
  },
  props: {
    time: {
      type: [Number, String],
      default: 0,
    },
    text: {
      type: String,
      default: "",
    },
  },
  methods: {},
  computed: {},
};
</script>
<style lang='scss' scoped>
.clock-box{
    margin-bottom: 5px;
}
</style>