import Vue from "vue";

export default {
	url: "/api-client/Client/",
	requestUrl: `/api-shop/LotRequest/`,
	purchasesUrl: `/api-shop/DirectPurchase/`,
	api: Vue.prototype.$api,

	async getAllOffer(params) {
		return await this.api.get(`${this.url}GetAllOffer`, {
			params: params,
		});
	},

	async getAllOfferByRegion(params) {
		return await this.api.get(`${this.url}GetAllOfferByRegion`, {
			params: params,
		});
	},

	getByOffer(offerNumber) {
		return this.api.get(`${this.url}GetByOffer`, {
			params: { offerNumber: offerNumber },
		});
	},

	getSimilarProducts(params) {
		return this.api.get(`${this.url}GetAllSimilarProducts`, {
			params: params,
		});
	},

	getExpenditureItems() {
		return this.api.get(`${this.requestUrl}GetListAllExpenses`);
	},

	async addRequest(data) {
		return await this.api.post(`${this.requestUrl}AddLots`, data);
	},

	checkRequest(offerId) {
		return this.api.get(`${this.requestUrl}CheckRequest?offerId=${offerId}`);
	},
	getShopCount() {
		return this.api.get(`${this.url}GetAllShopCount`);
	},
};

