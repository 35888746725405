import { render, staticRenderFns } from "./CounterBox.vue?vue&type=template&id=25a86fee&scoped=true&"
import script from "./CounterBox.vue?vue&type=script&lang=js&"
export * from "./CounterBox.vue?vue&type=script&lang=js&"
import style0 from "./CounterBox.vue?vue&type=style&index=0&id=25a86fee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a86fee",
  null
  
)

export default component.exports