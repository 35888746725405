<template>
	<div class="product-card">
		<div class="product-card__photo">
			<img :src="photo" :alt="photo" />
			<div class="product-id">
				{{ product.offerNumber }}
			</div>
			<div class="product-accessibility" v-if="product.hasDisability">
				<img src="@/assets/icons/shop_img1.svg" alt="" />
				<div class="tooltip">
					<p>{{ $t("goodsOfOrganizationsRelatedPersonsWithDisabilities") }}</p>
				</div>
			</div>
			<div v-if="product.isCertificate" class="product-sertificated">{{ $t("certificated") }}</div>
		</div>

		<div class="product-card__content" :class="isMobileSmall ? 'pa-10' : 'pa-30'">
			<AppText size="14" line-height="21" weight="700" class="mb-28">{{ product.productName | translate }} </AppText>

			<div class="block_item">
				<div class="mb-10 pr-30">
					<app-button theme="main" sides="16" height="40" class="w-100" font-size="14">{{ $t("more") }} </app-button>
				</div>
				<div class="mb-10">
					<reverse-counter
						:days="getCounterTime.days"
						:hours="getCounterTime.hours"
						:minutes="getCounterTime.minutes"
						:fullDate="getCounterTime.fullDate"
					/>
				</div>
			</div>

			<block-wrap count="2" offset-x="15" offset-y="16">
				<div>
					<app-text size="12" line-height="16" class="color-text-light mb-5" weight="500">{{ $t("amount") }} </app-text>
					<app-text size="14" line-height="18" weight="600" class="color-text">
						{{ product.productQuantity }}
						{{ product.measureName }}
					</app-text>
				</div>

				<div>
					<app-text size="12" line-height="16" class="color-text-light mb-5" weight="500"
						>{{ $t("minimumAmount") }}
					</app-text>
					<app-text size="14" line-height="18" weight="600" class="color-text"
						>{{ product.minPart }} {{ product.measureName }}
					</app-text>
				</div>
				<div>
					<app-text size="12" line-height="16" weight="500" class="color-text-light mb-5">{{ $t("price") }} </app-text>

					<app-text size="14" line-height="18" class="color-text" weight="600"
						>{{ setCorrectPrice(product.unitPrice) }} {{ $t("sum") }}
					</app-text>
				</div>
				<div v-if="product.isAutoRenewalOffer === false">
					<app-text size="12" line-height="16" weight="500" class="color-text-light mb-5"
						>{{ $t("expirationDate") }}
					</app-text>
					<app-text size="14" line-height="18" weight="600" class="color-text"
						>{{ product.publicEndDate | moment("DD.MM.YYYY HH:mm") }}
					</app-text>
				</div>
			</block-wrap>
		</div>
	</div>
</template>

<script>
import AppText from "./AppText";
import AppButton from "./AppButton";
import AppTimer from "./AppTimer";
import BlockWrap from "./BlockWrap";
import ReverseCounter from "@/components/pages/ProductList/reverse-counter/ReverseCounter";
import noProduct from "@/assets/images/no-photo.png";
import SelectionTimeCounter from "@/components/pages/selection/SelectionTimeCounter";
import { correctPrice } from "../../static/formatter";

export default {
	name: "AppProductCard",
	props: {
		product: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
		};
	},
	created() {},
	components: {
		BlockWrap,
		AppTimer,
		AppButton,
		AppText,
		ReverseCounter,
		SelectionTimeCounter,
	},
	methods: {
		setCorrectPrice(val) {
			return correctPrice(String(val));
		},
		reloadPage(id) {
			this.$emit("change", id);
			// this.$router.push(`/product-list/${id}`);
			this.$router.push({
				name: "product-detail",
				params: {
					id,
				},
			});
			window.scrollTo(0, 0);
		},
	},
	computed: {
		photo() {
			let correctFormat = this.product.photos?.replaceAll(";", "|");
			let separator = correctFormat?.includes("|") ? "|" : ";";
			const image = correctFormat?.split(separator).find((item) => item !== "null" && item !== "");
			return this.baseUrl + image;
		},
		getCounterTime() {
			const publicDate = this.$moment(this.product.publicEndDate),
				today = new Date(),
				minutesAll = this.$moment.duration(publicDate.diff(today)).asMinutes(),
				purchasingPeriod = this.$moment(this.product.statusDate);

			let fullLength = this.$moment.duration(publicDate.diff(purchasingPeriod)).asMinutes();
			const minutes = Math.floor(minutesAll % 60),
				hours = Math.floor((minutesAll / 60) % 24),
				days = Math.floor(minutesAll / (60 * 24));
			fullLength = Math.floor(fullLength / (60 * 24));
			let fullDate = this.product.publicEndDate;
			return { minutes, hours, days, fullLength, fullDate };
		},
	},
};
</script>

<style lang="scss" scoped>
.product-card {
	border-radius: 10px;
	// overflow: hidden;
	transition: 0.3s;
	background-color: #fff;
	min-height: 530px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:hover {
		box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.05);
		border-color: transparent;
	}

	&__photo {
		width: 100%;
		overflow: hidden;
		max-height: 270px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border-radius: 10px 10px 0 0;

		img {
			width: auto;
			height: 100%;
			max-height: 400px;
			object-fit: cover;
			cursor: pointer;
		}

		p.title {
			position: absolute;
			top: 15px;
			right: 15px;
			border-radius: 3px;
			padding: 5px;
			font-size: 12px;
			font-weight: bolder;
		}
	}
}

.block_item {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.product-sertificated,
.product-id {
	background-color: #ffffff;
	color: #5575a8;
	position: absolute;
	top: 15px;
	right: 15px;
	border-radius: 8px;
	padding: 8px 12px;
	font-size: 14px;
	font-weight: 600;
	font-family: "MazzardHSemibold";
}
.product-accessibility {
	position: absolute;
	bottom: 5px;
	right: 15px;
	img {
		border: 1px solid #c78e94;
		border-radius: 8px;
		width: 35px;
		height: 35px;
	}
	.tooltip {
		position: absolute;
		width: 150px;
		padding: 10px;
		background-color: #000;
		color: #fff;
		border-radius: 4px;
		bottom: 5px;
		right: 45px;
		display: none;
		p {
			font-size: 12px;
			line-height: 16px;
		}
	}
	&:hover .tooltip {
		display: block;
	}
}

.product-id {
	left: 15px;
	width: fit-content;
}

@media (max-width: 600px) {
	.block_item {
		justify-content: center;
	}
}
</style>
